import { helper } from '@ember/component/helper';
import { get } from '@ember/object';
import { defaultMatcher } from 'ember-power-select/utils/group-utils';

export function matchBy(fields, { separator = ' ' } = {}) {
  return function (item, term) {
    let concatenation = fields.map((f) => get(item, f)).join(separator);
    return defaultMatcher(concatenation, term);
  };
}

export default helper(matchBy);

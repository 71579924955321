import Service from '@ember/service';
import { debug } from '@ember/debug';
import * as Sentry from '@sentry/ember';

export default class LogService extends Service {
  error(message: string, error: unknown = undefined) {
    Sentry.captureMessage(message);
    if (error) {
      Sentry.captureException(error);
    }
    debug('Error: ' + message + '->' + error);
  }
}

import V2ApiAdapter from './v2-api';

export default class RawEventAdapter extends V2ApiAdapter {
  buildURL(modelName, id, snapshot, _requestType, _query) {
    const containerId = snapshot?.adapterOptions?.containerId || snapshot?.record?.get('container.id');

    if (!containerId) {
      throw new Error('Container ID is required for raw events');
    }

    return `${super.buildURLV2('container', containerId)}/raw_events`;
  }
}

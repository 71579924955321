import { modifier } from 'ember-modifier';
import tippyJS, { type Props } from 'tippy.js';

interface TooltipArgs {
  placement?: string;
  [key: string]: any;
}

interface TooltipSignature {
  Element: HTMLElement;
  Args: {
    [key: string]: any;
  };
}

const tooltip = modifier<TooltipSignature>(function tooltip(
  element: HTMLElement,
  _params: unknown[],
  hash: TooltipArgs,
) {
  if ((element as any)._tippy) {
    (element as any)._tippy.destroy();
  }

  if ([undefined, 'hide'].includes(hash.placement)) {
    return;
  }

  tippyJS(element, hash as Props);
});

export default tooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    tooltip: typeof tooltip;
  }
}

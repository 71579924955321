import V2ApiAdapter from './v2-api';

export default class VesselAdapter extends V2ApiAdapter {
  urlForQuery(query, modelName) {
    if (query.isSearch) {
      delete query.isSearch;
      return `${this.buildURL(modelName)}/search`;
    }
    return super.urlForQuery(...arguments);
  }
}

import ArrayFeesComponent from 'tnt-ui/components/containers-new/array/fees/index';
import ArrayHoldsComponent from 'tnt-ui/components/containers-new/array/holds/index';
import ArrayIssuesComponent from 'tnt-ui/components/containers-new/array/issues/index';
import ArrayReferenceComponent from 'tnt-ui/components/containers-new/array/reference/index';
import ArrayTagsComponent from 'tnt-ui/components/containers-new/array/tags/index';
// @ts-ignore
import AvailableForPickup from 'tnt-ui/components/containers-new/available-for-pickup';
// @ts-ignore
import BolComponent from 'tnt-ui/components/containers-new/bol/index';
// @ts-ignore
import ContainerNumberComponent from 'tnt-ui/components/containers-new/container-number/index';
// @ts-ignore
import ContainerTypeComponent from 'tnt-ui/components/containers-new/container-type/index';
// @ts-ignore
import DataConfidenceComponent from 'tnt-ui/components/containers-new/data-confidence/index';
// @ts-ignore
import DatetimeComponent from 'tnt-ui/components/containers-new/datetime/index';
// @ts-ignore
import DaysSincePickupComponent from 'tnt-ui/components/containers-new/days-since-pickup/index';
// @ts-ignore
import EtaComponent from 'tnt-ui/components/containers-new/eta/index';
// @ts-ignore
import LFDComponent from 'tnt-ui/components/containers-new/lfd/index';
// @ts-ignore
import PickupFacilityComponent from 'tnt-ui/components/containers-new/pickup-facility/index';
// @ts-ignore
import PortComponent from 'tnt-ui/components/containers-new/port/index';
// @ts-ignore
import StringComponent from 'tnt-ui/components/containers-new/string/index';

let order = 0;

export interface ColumnType {
  id: string;
  name: string;
  key: string;
  tooltip: string;
  Cell: any;
  sticky?: boolean;
  order: number;
  notRemovable?: boolean;
  minWidth: number;
  sortable?: boolean;
  anonymizable?: boolean;
  meta?: any;
  featureFlag?: string;
  disabled?: boolean;
  group: string;
}

export const columnDefinitions = [
  {
    id: 'container_number',
    name: 'Container',
    key: 'number',
    tooltip: 'Container number',
    Cell: ContainerNumberComponent,
    sticky: true,
    order: order++,
    notRemovable: true,
    minWidth: 125,
    group: 'Attribute',
  },
  {
    id: 'shipment_number',
    name: 'Shipment',
    key: 'shipment.billOfLadingNumber',
    tooltip: 'Shipment BL or Booking number',
    Cell: BolComponent,
    order: order++,
    minWidth: 185,
    group: 'Attribute',
  },
  {
    id: 'shipment_ref_numbers',
    name: 'Ref numbers',
    key: 'shipment.refNumbers',
    tooltip: 'Shipment reference numbers',
    Cell: ArrayReferenceComponent,
    order: order++,
    anonymizable: true,
    minWidth: 100,
    group: 'Attribute',
  },
  {
    id: 'carrier',
    name: 'Shipping line',
    key: 'shipment.shippingLineShortName',
    tooltip: 'Shipping Line or Carrier',
    Cell: StringComponent,
    order: order++,
    minWidth: 100,
    group: 'Attribute',
  },
  {
    id: 'shipment_tags',
    name: 'Shipment tags',
    key: 'shipment.tags',
    tooltip: 'Tags related to a shipment',
    Cell: ArrayTagsComponent,
    order: order++,
    anonymizable: true,
    minWidth: 200,
    group: 'Attribute',
  },
  {
    id: 'scac',
    name: 'SCAC',
    key: 'shipment.shippingLineScac',
    tooltip: 'Standard Carrier Alpha Code',
    Cell: StringComponent,
    order: order++,
    minWidth: 100,
    group: 'Attribute',
  },
  {
    id: 'customer_name',
    name: 'Customer',
    key: 'shipment.customerName',
    tooltip: 'Customer or company name',
    Cell: StringComponent,
    order: order++,
    anonymizable: true,
    minWidth: 100,
    group: 'Attribute',
  },
  {
    id: 'created_at',
    name: 'Date added',
    key: 'createdAt',
    tooltip: 'Date when shipment started tracking on Terminal49',
    Cell: DatetimeComponent,
    sortable: true,
    order: order++,
    minWidth: 100,
    group: 'Attribute',
  },
  {
    id: 'eq_type',
    name: 'Equipment',
    key: 'equipmentType',
    tooltip: 'Equipment size, type, height',
    Cell: ContainerTypeComponent,
    order: order++,
    minWidth: 120,
    group: 'Attribute',
  },
  {
    id: 'current_issues',
    name: 'Current issues',
    key: 'currentIssues',
    tooltip: 'Type of issue: hold, fee or other',
    Cell: ArrayIssuesComponent,
    order: order++,
    minWidth: 150,
    group: 'Issues',
  },
  {
    id: 'port_of_origin',
    name: 'POL',
    key: 'shipment.portOfLadingName',
    tooltip: 'Port of Lading',
    Cell: PortComponent,
    order: order++,
    meta: { type: 'port_of_lading' },
    minWidth: 100,
    group: 'Export',
  },
  {
    id: 'pol_etd_at',
    name: 'POL ETD',
    key: 'shipment.polEtdAt',
    tooltip: 'Port of Lading Estimated Time of Departure',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'shipment.polTimezone' },
    minWidth: 100,
    group: 'Export',
  },
  {
    id: 'pol_atd_at',
    name: 'POL ATD',
    key: 'shipment.polAtdAt',
    tooltip: 'Port of Lading Actual Time of Departure',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'shipment.polTimezone' },
    minWidth: 100,
    group: 'Export',
  },
  {
    id: 'pod_terminal_name',
    name: 'POD Terminal',
    key: 'podTerminal.fullName',
    tooltip: 'Port of Discharge Terminal name and FIRMS code',
    Cell: StringComponent,
    order: order++,
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'pod_eta_at',
    name: 'POD ETA',
    key: 'shipment.podEtaAt',
    tooltip: 'Port of Discharge Estimated Time of Arrival',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'shipment.podTimezone' },
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'arrival',
    name: 'POD Arrival',
    key: 'eta',
    tooltip: 'Port of Discharge Estimated or Actual Arrival Time',
    Cell: EtaComponent,
    order: order++,
    sortable: true,
    minWidth: 125,
    group: 'Import',
  },
  {
    id: 'pod_arrived_at',
    name: 'POD ATA',
    key: 'podArrivedAt',
    tooltip: 'Port of Discharge Actual Time of Arrival',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'podTimezone' },
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'vessel',
    name: 'POD Vessel Voyage',
    key: 'shipment.vesselAndVoyage',
    tooltip: 'Port of Discharge Arrival vessel and voyage number',
    Cell: StringComponent,
    order: order++,
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'pickup_lfd',
    name: 'POD LFD',
    key: 'pickupLfd',
    tooltip: 'Port of Discharge Last Free Day',
    Cell: LFDComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'podTimezone', format: 'MMM D' },
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'pickup_facility_lfd_on',
    name: 'IND LFD',
    key: 'indFacilityLfdOn',
    tooltip: 'Inland Destination Last Free Day',
    Cell: LFDComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'pickupFacilityTimeZone', format: 'MMM D' },
    minWidth: 100,
    group: 'Import',
    featureFlag: 'rail-data',
  },
  {
    id: 'fees_at_pod',
    name: 'POD Fees',
    key: 'feesAtPodTerminal',
    tooltip: 'Port of Discharge Fees',
    Cell: ArrayFeesComponent,
    order: order++,
    minWidth: 100,
    group: 'Issues',
  },
  {
    id: 'holds_at_pod',
    name: 'POD Holds',
    key: 'holdsAtPodTerminal',
    tooltip: 'Port of Discharge Holds',
    Cell: ArrayHoldsComponent,
    order: order++,
    minWidth: 100,
    group: 'Issues',
  },
  {
    id: 'pod_terminal_location',
    name: 'POD Yard Location',
    key: 'locationAtPodTerminal',
    tooltip: 'Current location information from the POD Terminal',
    Cell: StringComponent,
    order: order++,
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'available_for_pickup',
    name: 'POD Availability',
    key: 'availableForPickup',
    tooltip: 'Container without issues, available for pickup',
    Cell: AvailableForPickup,
    order: order++,
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'picked_up_at',
    name: 'POD Full Out',
    key: 'pickedUpAt',
    tooltip: 'Port of Discharge Full Out Date',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'pickup_appointment_at',
    name: 'POD Pickup Appointment',
    key: 'pickupAppointmentAt',
    tooltip: 'Port of Discharge Pickup Appointment date',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'podTimezone' },
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'pod_chassis_number',
    name: 'POD Pickup Chassis',
    key: 'podFullOutChassisNumber',
    tooltip: 'Port of Discharge Full Out Chassis Number',
    Cell: StringComponent,
    order: order++,
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'days_since_pickup',
    name: 'POD Days since Full Out',
    key: 'daysSincePickup',
    tooltip: 'Port of Discharge number of days since Full Out',
    Cell: DaysSincePickupComponent,
    order: order++,
    sortable: false,
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'port_of_discharge',
    name: 'POD',
    key: 'shipment.portOfDischargeName',
    tooltip: 'Port of Discharge',
    Cell: PortComponent,
    order: order++,
    meta: { type: 'port_of_discharge', destination: 'destinationTerminal' },
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'pod_discharged_at',
    name: 'POD Discharged',
    key: 'podDischargedAt',
    tooltip: 'POD Discharged date',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'podTimezone' },
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'empty_returned_at',
    name: 'Empty Returned',
    key: 'emptyTerminatedAt',
    tooltip: 'Empty Returned date',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    meta: { timezone: 'emptyTerminatedTimezone' },
    minWidth: 100,
    group: 'Import',
  },
  {
    id: 'data_confidence',
    name: 'Confidence',
    key: 'created_at',
    Cell: DataConfidenceComponent,
    order: order++,
    sortable: false,
    disabled: true,
    minWidth: 100,
    group: 'Attribute',
  },
  // Rail Attributes
  {
    id: 'destination',
    name: 'IND',
    key: 'shipment.destinationName',
    tooltip: 'INland Destination (final transportation transportation hub)',
    Cell: PortComponent,
    order: order++,
    meta: { type: 'destination', destination: 'destination' },
    minWidth: 100,
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'pickup_facility',
    name: 'IND Terminal',
    key: 'pickupFacilityName',
    tooltip: 'Inland Destination Terminal',
    Cell: PickupFacilityComponent,
    order: order++,
    meta: { icon: 'pickupFacilityIcon' },
    minWidth: 150,
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'pickup_rail_carrier_scac',
    name: 'Rail Carrier',
    key: 'railCarrierScac',
    tooltip: 'Rail Carrier(s) SCAC for inland transportation',
    Cell: StringComponent,
    order: order++,
    minWidth: 100,
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'pod_rail_loaded_at',
    name: 'POD Rail Loaded',
    key: 'podRailLoadedAt',
    tooltip: 'Container loaded on Rail at POD',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    minWidth: 100,
    meta: { timezone: 'podTimezone' },
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'pod_rail_departed_at',
    name: 'POD Rail Departed',
    key: 'podRailDepartedAt',
    tooltip: 'Container departed on Rail from POD',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    minWidth: 100,
    meta: { timezone: 'podTimezone' },
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'inland_destination_eta_at',
    name: 'IND ETA',
    key: 'indEtaAt',
    tooltip: 'Inland Destination Estimated Time of Arrival',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    minWidth: 100,
    meta: { timezone: 'finalDestinationTimezone' },
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'inland_destination_ata_at',
    name: 'IND ATA',
    key: 'indAtaAt',
    tooltip: 'Inland Destination Actual Time of Arrival',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    minWidth: 100,
    meta: { timezone: 'finalDestinationTimezone' },
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'inland_destination_rail_unloaded_at',
    name: 'IND Unloaded',
    key: 'indRailUnloadedAt',
    tooltip: 'Inland Destination Unloaded Date',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    minWidth: 100,
    meta: { timezone: 'finalDestinationTimezone' },
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'final_destination_full_out_at',
    name: 'IND Full Out',
    key: 'finalDestinationFullOutAt',
    tooltip: 'Inland Destination Full Out Date',
    Cell: DatetimeComponent,
    order: order++,
    sortable: true,
    minWidth: 100,
    meta: { timezone: 'finalDestinationTimezone' },
    featureFlag: 'rail-data',
    group: 'Import Inland',
  },
  {
    id: 'ind_days_since_pickup',
    name: 'IND Days since Full Out',
    key: 'indDaysSincePickup',
    tooltip: 'Inland Destination number of days since Full Out',
    Cell: DaysSincePickupComponent,
    order: order++,
    sortable: false,
    minWidth: 100,
    featureFlag: 'rail-data',
  },
];

export default columnDefinitions;

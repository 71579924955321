import Trigger from 'ember-power-select/components/power-select-multiple/trigger';

const ua = window && window.navigator ? window.navigator.userAgent : '';
const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

export default class EmberPowerSelectTagTrigger extends Trigger {
  get maybePlaceholder() {
    if (isIE) {
      return undefined;
    }
    return this.args?.placeholder || '';
  }
}

import { camelizeKeys, dasherizeKeys } from '../utils/object-functions';
import ApplicationSerializer from './application';

export default class AccountSerializer extends ApplicationSerializer {
  normalize(typeClass, hash) {
    // Dasherize attributes keys and relationships keys
    // In order to support response from V2 Serializer
    hash.attributes = dasherizeKeys(hash.attributes);
    hash.relationships = dasherizeKeys(hash.relationships);

    const normalizedHash = super.normalize(typeClass, hash);

    if (normalizedHash.data.attributes.flags) {
      normalizedHash.data.attributes.flags = camelizeKeys(normalizedHash.data.attributes.flags);

      if (normalizedHash.data.attributes.flagDetails) {
        normalizedHash.data.attributes.flagDetails = camelizeKeys(normalizedHash.data.attributes.flagDetails);

        Object.keys(normalizedHash.data.attributes.flags).forEach((flag) => {
          if (normalizedHash.data.attributes.flagDetails[flag]) {
            normalizedHash.data.attributes.flagDetails[flag] = camelizeKeys(
              normalizedHash.data.attributes.flagDetails[flag],
            );
          }
        });
      }
    }

    return normalizedHash;
  }
}

/* eslint-disable decorator-position/decorator-position, ember/no-computed-properties-in-native-classes, ember/require-computed-property-dependencies */

import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { computed } from '@ember/object';
import { alias, bool, empty, filter, filterBy, not, notEmpty, or, reads } from '@ember/object/computed';
import { isBlank } from '@ember/utils';
import { task } from 'ember-concurrency';
import moment from 'moment-timezone';
import { hasStatus, usedAs } from 'tnt-ui/utils/decorators';
import { OPS_STEPS } from 'tnt-ui/utils/t49-operations-steps';
import Validations from '../utils/validations/cargo';

export const New = 'new',
  Loaded = 'loaded',
  Grounded = 'grounded',
  Available = 'available',
  NotAvailable = 'not_available',
  OnShip = 'on_ship',
  OnRail = 'on_rail',
  PickedUp = 'picked_up',
  Dropped = 'dropped',
  Delivered = 'delivered',
  EmptyReturned = 'empty_returned',
  AwaitingInlandTransfer = 'awaiting_inland_transfer',
  OffDock = 'off_dock';

export const ALL_STATES = [
  New,
  Loaded,
  Available,
  NotAvailable,
  OnRail,
  Grounded,
  OnShip,
  PickedUp,
  Dropped,
  Delivered,
  OffDock,
  EmptyReturned,
  AwaitingInlandTransfer,
];

@usedAs(['subjectable', 'attachable'])
@hasStatus(ALL_STATES, 'currentStatus')
export default class CargoModel extends Model.extend(Validations) {
  @belongsTo('shipment') shipment;
  @belongsTo('booking-order') bookingOrder;
  @belongsTo('cntnr') cntnr;
  @belongsTo('account') truckingAccount;
  @belongsTo('user') opsStepChangedBy;
  @belongsTo('rate') truckingRate;
  @hasMany('document') documents;
  @hasMany('offer') offers;
  @hasMany('payout') payouts;
  @hasMany('charge') charges;
  @hasMany('cargo-product') cargoProducts;
  @hasMany('cargo-shipment-event') cargoShipmentEvents;
  @belongsTo('user-cargo', { async: false }) userCargo;

  @attr('string') number;
  @attr('array') refNumbers;
  @attr('string') sealNumber;
  @attr('string') chassisNumber;
  @attr('string') emptyPickdropLocation;
  @attr('number', { defaultValue: 40 }) containerLength;
  @attr('string', { defaultValue: 'dry' }) containerType;
  @attr('string', { defaultValue: 'standard' }) containerHeight;
  @attr('number') weight;
  @attr('number') tareWeight;
  @attr('string') notes;
  @attr('string', { defaultValue: 'new' }) currentStatus;
  @attr('date') currentStatusAt;
  @attr('string') lastStatusRefreshAt;
  @attr('date', { defaultValue: () => null }) pickupAppointmentAt;
  @attr('date', { defaultValue: () => null }) deliveryAppointmentAt;
  @attr('plaindate', { defaultValue: () => null }) pickupAppointmentOn;
  @attr('plaindate', { defaultValue: () => null }) deliveryAppointmentOn;
  @attr('date') pickedUpAt;
  @attr('date') deliveredAt;
  @attr('date') emptyReturnedAt;
  @attr('string', { defaultValue: 'initial_step' }) opsStepId;
  @attr('date', { defaultValue: () => new Date() }) opsStepAt;
  @attr('boolean', { defaultValue: false }) drayedByT49;
  @attr('array') currentIssues;
  @attr('string') pickupRailCarrierScac;
  @attr('string') destinationRailCarrierScac;

  @or('isAvailable', 'isNotAvailable', 'isGrounded', 'isOffDock') inTerminal;
  @or('on_ship', 'new') onShip;
  @bool('deliveredAt') delivered;
  @reads('isPickedUp') pickedUp;
  @not('isNew') isSaved;
  @reads('bookingOrder.timeZone') timeZone;
  @reads('bookingOrder.bookingType') bookingType;
  @reads('bookingOrder.isExport') isExport;
  @reads('bookingOrder.isImport') isImport;
  @reads('bookingOrder.customer') customer;
  @filterBy('documents', 'category', 'proof_of_delivery') podDocuments;
  @empty('podDocuments') emptyPodDocuments;
  @alias('cargoProducts') products;
  @or('deliveryAppointmentAt', 'pickupAppointmentAt', 'deliveryAppointmentOn', 'pickupAppointmentOn')
  pendingAppointments;
  @not('pendingAppointments') noAppointmentsSet;
  @notEmpty('charges') hasCharges;
  @not('drayedByT49') managedByCustomer;

  @reads('cntnr.lastFreeDayOn') lastFreeDayOn;
  @reads('cntnr.holds') holds;
  @reads('cntnr.fees') fees;
  @reads('cntnr.hasFees') hasFees;
  @reads('cntnr.hasHolds') hasHolds;
  @reads('cntnr.lastTerminalUpdateTimestampAt') lastTerminalUpdateTimestampAt;

  @belongsTo('terminal', { async: true }) podTerminal;

  @filter('cargoShipmentEvents', (e) => !e.estimated) actualCargoShipmentEvents;
  @notEmpty('cargoShipmentEvents') hasActualCargoShipmentEvents;

  @computed('currentIssues')
  get showMarkAsDelivered() {
    return this.currentIssues.includes('no_delivery_date');
  }

  @computed('currentIssues')
  get showUploadPOD() {
    return this.currentIssues.includes('no_proof_of_delivery_uploaded');
  }

  @computed('shipment')
  get drayable() {
    return this.shipment.get('customer.flags.setupDelivery');
  }

  @computed('opsStepId')
  get currentOpsStep() {
    return OPS_STEPS.findBy('id', this.opsStepId);
  }

  @computed('currentOpsStep')
  get nextOpsStep() {
    if (this.currentOpsStep) {
      return OPS_STEPS.findBy('index', this.currentOpsStep.index + 1);
    }
    return null;
  }

  @computed('offers.@each.currentStatus')
  get offerStatus() {
    if (this.offers.isAny('isAccepted')) {
      return 'accepted';
    }
    if (this.offers.isAny('isSent')) {
      return 'waiting';
    }
    if (this.offers.isAny('isExpired')) {
      return 'expired';
    }
    if (this.offers.isAny('isDeclined')) {
      return 'declined';
    }
    return 'unoffered';
  }

  @computed('offers.[]')
  get activeOffer() {
    return this.offers.findBy('isAccepted') || this.offers.findBy('isSent');
  }

  @task truckerLookup = function* () {
    try {
      let truckingAccount = yield this.truckingAccount,
        offerTruckingAccount;

      yield this.offers;
      if (this.activeOffer) {
        offerTruckingAccount = yield this.activeOffer.truckingAccount;
      }

      if (truckingAccount) {
        return truckingAccount.companyName;
      }
      if (offerTruckingAccount) {
        return offerTruckingAccount.get('companyName');
      }
      return 'TBD';
    } catch (e) {
      console.error('Error looking up trucker:' + e.stack);
    }
  };

  @computed('truckingAccount', 'activeOffer')
  get truckerName() {
    return this.truckerLookup.perform();
  }

  @computed('opsStepId', 'opsStepAt')
  get deliveryAppointmentPastDue() {
    return this.opsStepId === 'set_drop_appointment' && moment().subtract(12, 'hours').isAfter(this.opsStepAt);
  }

  @computed('opsStepId', 'deliveryAppointmentAt', 'deliveryAppointmentOn')
  get proofOfDeliveryPastDue() {
    let dateComp = false;
    if (isBlank(this.deliveryAppointmentAt)) {
      dateComp = moment().startOf('day').isAfter(moment(this.deliveryAppointmentOn).startOf('day'));
    } else {
      dateComp = moment().subtract(12, 'hours').isAfter(this.deliveryAppointmentAt);
    }
    return this.opsStepId === 'upload_pod' && dateComp;
  }

  @computed('cntnr.lastFreeDayOn', 'pickupAppointmentAt', 'pickupAppointmentOn')
  get pickupAppointmentPastDue() {
    return (
      isBlank(this.pickupAppointmentAt) &&
      isBlank(this.pickupAppointmentOn) &&
      moment().add(1, 'day').isAfter(this.lastFreeDayOn)
    );
  }

  @computed('pickupAppointmentAt')
  get pickupAppointmentAtLocation() {
    return this.appointmentStatusToTime(this.pickupAppointmentAt);
  }

  @computed('deliveryAppointmentAt')
  get deliveryAppointmentAtLocation() {
    return this.appointmentStatusToTime(this.deliveryAppointmentAt);
  }

  appointmentStatusToTime = function (value) {
    if (this.timeZone && value) {
      return moment(moment.tz(value, this.timeZone).format('YYYY-MM-DDTHH:mm')).toDate();
    }
    return value;
  };
}

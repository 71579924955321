/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { isEqual } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import type MetricsService from 'tnt-ui/services/metrics';
import type ModalsService from 'tnt-ui/services/modals';
import type SessionService from 'tnt-ui/services/session';
import { type ColumnType } from 'tnt-ui/utils/views/new-column-definitions';

interface SortFilter {
  id: 'grouping_order' | 'alphabetical_order' | 'supply_chain_order';
  name: string;
  sortField: 'group' | 'name' | 'order';
}

interface ColumnsManagementSignature {
  Element: HTMLDivElement;
  Args: {
    content: {
      columns: ColumnType[];
      availableColumns: ColumnType[];
      onSave: (columns: ColumnType[]) => void;
    };
  };
  Blocks: {};
}

const GROUP_ORDER = ['Attribute', 'Issues', 'Export', 'Import', 'Import Inland'];

export default class ModalsColumnsManagementComponent extends Component<ColumnsManagementSignature> {
  @service declare metrics: MetricsService;
  @service declare modals: ModalsService;
  @service declare session: SessionService;

  sortFilters: readonly SortFilter[] = [
    {
      id: 'grouping_order',
      name: 'Grouping order',
      sortField: 'group',
    },
    {
      id: 'alphabetical_order',
      name: 'Alphabetical order',
      sortField: 'name',
    },
    {
      id: 'supply_chain_order',
      name: 'Supply chain order',
      sortField: 'order',
    },
  ] as const;

  @tracked search = '';
  @tracked columns = [...this.args.content.columns];
  @tracked currentSortFilter = this.sortFilters[0];

  get account() {
    return this.session.currentUser?.currentAccount;
  }

  get isTrialActive() {
    const currentUser = this.session.currentUser;

    return currentUser?.currentAccount?.billingAccount?.get('isTrialActive');
  }

  get sortedColumns(): { [key: string]: ColumnType[] } | ColumnType[] {
    const columns = this.filteredColumns;

    switch (this.currentSortFilter?.id) {
      case 'grouping_order': {
        const groupedColumns = columns.reduce(
          (acc, column) => {
            const group = column.group || 'Ungrouped';
            if (!acc[group]) {
              acc[group] = [];
            }
            acc[group]?.push(column);
            return acc;
          },
          {} as { [key: string]: ColumnType[] },
        );

        // Sort the groups based on the GROUP_ORDER
        return this.sortGroups(groupedColumns);
      }

      case 'alphabetical_order':
        return columns.sort((a, b) => (a.name || '').localeCompare(b.name || ''));

      case 'supply_chain_order':
        return columns.sort((a, b) => (a.order || 0) - (b.order || 0));

      default:
        return columns;
    }
  }

  private sortGroups(groupedColumns: { [key: string]: ColumnType[] }): { [key: string]: ColumnType[] } {
    const sortedGroups: { [key: string]: ColumnType[] } = {};

    // Add groups in the specified order
    GROUP_ORDER.forEach((groupName) => {
      if (groupedColumns[groupName]) {
        sortedGroups[groupName] = groupedColumns[groupName] as ColumnType[];
      }
    });

    // Add any remaining groups that weren't in GROUP_ORDER
    Object.keys(groupedColumns).forEach((groupName) => {
      if (!sortedGroups[groupName]) {
        sortedGroups[groupName] = groupedColumns[groupName] as ColumnType[];
      }
    });

    return sortedGroups;
  }

  get filteredColumns() {
    if (this.search) {
      return this.args.content.availableColumns.filter(({ name }) =>
        name.toLowerCase().includes(this.search.toLowerCase()),
      );
    } else {
      return this.args.content.availableColumns;
    }
  }

  @action
  onClearAll() {
    this.columns = this.args.content.availableColumns.filter(({ notRemovable }) => notRemovable);
  }

  @action
  onCheck(checkedColumn: ColumnType) {
    const column = this.columns.find(({ id }) => isEqual(id, checkedColumn.id));

    if (column) {
      this.columns.removeObject(column);

      this.trackEvent({
        event: 'column_unchecked',
        column: column.name,
        columns: this.columns.map(({ name }) => name),
        feature_flag: column.featureFlag,
      });
    } else {
      this.columns.addObject(checkedColumn);

      this.trackEvent({
        event: 'column_checked',
        column: checkedColumn?.name,
        columns: this.columns.map(({ name }) => name),
        feature_flag: checkedColumn.featureFlag,
      });
    }
  }

  @action
  onClear() {
    this.search = '';
  }

  @action
  onEnter(e: KeyboardEvent) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.search = (e.target as HTMLInputElement).value;
    }
  }

  @action
  onAddSort(currentSortFilter: SortFilter) {
    this.currentSortFilter = currentSortFilter;
  }

  @action
  onSave() {
    this.args.content.onSave(this.columns);

    this.trackEvent({
      event: 'columns_saved',
      columns: this.columns.map(({ name }) => name),
    });
  }

  @action
  closeModal() {
    this.modals.close('columns-management');
  }

  @action
  trackEvent(attrs: Record<string, unknown>) {
    this.metrics.trackEvent({
      ...attrs,
      source: 'Columns management',
    });
  }
}

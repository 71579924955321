import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'tnt-ui/utils/authenticated-route';

export default class ProductsRoute extends AuthenticatedRoute {
  @service session;

  model() {
    return this.session.get('currentUser.currentAccount.products');
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * @param {Array<ProductModel>}        products
 * @param {UserModel}                  currentUser
 */
export default class ProductProductList extends Component {
  @service session;
  @service store;

  get currentUser() {
    return this.session.currentUser;
  }

  get currentAccount() {
    return this.currentUser.currentAccount;
  }

  @action
  newProduct() {
    this.store.createRecord('product', {
      account: this.currentAccount,
      user: this.currentUser,
    });
  }
}

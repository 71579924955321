/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { compare } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { transportEventMappings } from '../../../utils/event-name-mappings';

/**
 * @param {ContainerModel} container
 */

export default class CargoUnifiedEventView extends Component {
  @tracked eventHistoryEvent = null;

  @action
  openEventHistoryModal(event, clickEvent) {
    this.eventHistoryEvent = event;
    clickEvent.stopPropagation();
  }

  /* Opening the invalidate modal */

  @tracked invalidateModalEvent = null;
  @tracked createModalEvent = null;

  @action
  openInvalidateModal(event, clickEvent) {
    this.invalidateModalEvent = event;
    clickEvent.stopPropagation();
  }

  @action
  openCreateModal(event, clickEvent) {
    if (event == 'new') {
      this.createModalEvent = {};
    } else {
      const eventType = transportEventMappings()[event.event].rawEvents[0];
      this.createModalEvent = { event: eventType };
    }
    clickEvent.stopPropagation();
  }

  /* Filtering by source */

  @tracked sourceFilter = 'all';
  sourceFilterOptions = [
    { name: 'All', value: 'all' },
    { name: 'Shipping Line', value: 'shipping_line' },
    { name: 'Terminal', value: 'terminal' },
    { name: 'Rail Carrier', value: 'rail' },
  ];
  get selectedSourceFilterName() {
    return this.sourceFilterOptions.find((filter) => filter.value === this.sourceFilter)?.name;
  }

  /* Expanding/condensing the Unified Event View table */
  @tracked eventExpansions = {};

  @action
  toggleEventExpansion(eventId) {
    this.eventExpansions = {
      ...this.eventExpansions,
      [eventId]: !this.eventExpansions[eventId],
    };
  }

  @action
  toggleAllEventExpansions() {
    this.eventExpansions = this.unifiedEvents.reduce((acc, event) => {
      acc[event.id] = !this.allExpanded;
      return acc;
    }, {});
  }

  get allExpanded() {
    return this.unifiedEvents.every((event) => this.eventExpansions[event.id]);
  }

  /* the main method for creating unified event view */

  get unifiedEvents() {
    let remainingRawEvents = this.rawEvents.slice();
    const currentTransportEvents = this.peelOffPreviousVersions(this.actualTransportEvents);
    const invalidatedResults = this.connectSuccessfulTransportEvents(
      remainingRawEvents.filter((event) => event.invalidatedAt),
      currentTransportEvents.filter((event) => event.invalidatedAt),
    );
    const actualResults = this.connectSuccessfulTransportEvents(
      [...remainingRawEvents.filter((event) => !event.invalidatedAt), ...invalidatedResults.remainingRawEvents],
      currentTransportEvents.filter((event) => !event.invalidatedAt),
    );
    const estimatedResults = this.connectSuccessfulTransportEvents(
      actualResults.remainingRawEvents,
      this.estimatedTransportEvents,
      true,
    );

    let events = [
      ...invalidatedResults.connectedTransportEvents,
      ...actualResults.connectedTransportEvents,
      ...estimatedResults.connectedTransportEvents,
      ...estimatedResults.remainingRawEvents,
    ];

    events = this.fixLfdTransportEvents(events);
    events = this.markAndFilterEstimatedTransportEvents(events);
    events = events.sort((a, b) =>
      compare(
        typeof b.timestamp === 'string' ? b.timestamp : b.timestamp?.toISOString(),
        typeof a.timestamp === 'string' ? a.timestamp : a.timestamp?.toISOString(),
      ),
    );

    const unifiedEvents = this.orderEventsAndGroupRawEvents(events);
    if (this.sourceFilter !== 'all') {
      return unifiedEvents.filter((event) =>
        event.rawEvents.some((rawEvent) => rawEvent.dataSource === this.sourceFilter),
      );
    }
    return unifiedEvents;
  }

  /* Helper methods for unifiedEvents */

  get transportEvents() {
    const transportEvents = this.args.container?.transportEvents ?? [];
    return transportEvents.slice().sort((a, b) => compare(b.timestamp, a.timestamp));
  }

  get actualTransportEvents() {
    return this.transportEvents.filter(({ event }) => !event.includes('estimated'));
  }

  get estimatedTransportEvents() {
    const events = [];
    const estimatedTransportEvents = this.transportEvents.filter(({ event }) => event.includes('estimated'));
    estimatedTransportEvents
      .sort((a, b) => compare(b.createdAt, a.createdAt))
      .forEach((event) => {
        if (!events.find((e) => e.locationLocode === event.locationLocode && e.event === event.event)) {
          events.push(event);
        }
      });
    return events;
  }

  get rawEvents() {
    return this.args.container?.rawEvents ?? [];
  }

  timestampsEqual(date1, date2) {
    const d1 = date1 instanceof Date ? date1 : new Date(date1);
    const d2 = date2 instanceof Date ? date2 : new Date(date2);
    return d1.getTime() === d2.getTime();
  }

  shouldRawEventBeConnected(rawEvent, transportEvent, estimated = false) {
    if (rawEvent.id === transportEvent.sourceEvent?.id) {
      return true;
    }

    const sameTimestamp = this.timestampsEqual(rawEvent.timestamp, transportEvent.timestamp);
    const sameLocation = rawEvent.locationLocode == transportEvent.locationLocode;
    if (estimated) {
      return rawEvent.possibleTransportEvents.includes(transportEvent.event) && sameLocation;
    } else {
      return rawEvent.transportEvent == transportEvent.event && (sameLocation || sameTimestamp);
    }
  }

  connectSuccessfulTransportEvents(rawEvents, transportEvents, estimated = false) {
    let remainingRawEvents = rawEvents;
    const connectedTransportEvents = transportEvents
      .map((event) => {
        const rawEventsToConnect = remainingRawEvents.filter((rawEvent) =>
          this.shouldRawEventBeConnected(rawEvent, event, estimated),
        );
        rawEventsToConnect.forEach((rawEvent) => {
          if (rawEvent.id === event.sourceEvent?.id) {
            rawEvent.isSourceEvent = true;
          }
        });
        event.rawEvents = rawEventsToConnect.sort((a, b) => compare(a.createdAt, b.createdAt));
        remainingRawEvents = remainingRawEvents.filter((rawEvent) => {
          return !rawEventsToConnect.includes(rawEvent);
        });
        return event;
      })
      .compact();
    return {
      connectedTransportEvents,
      remainingRawEvents,
    };
  }

  peelOffPreviousVersions(transportEvents) {
    const previousVersionIds = transportEvents.map((event) => event.previousVersion?.id).compact();
    return transportEvents.filter((event) => !previousVersionIds.includes(event.id));
  }

  fixLfdTransportEvents(transportEvents) {
    return transportEvents.map((event) => {
      if (event.event === 'container.pickup_lfd.changed') {
        const rawEvent = event.rawEvents[0];
        event.willOccurAt = rawEvent?.willOccurAt || rawEvent?.actualAt || 'n/a'; // this will show 'Invalid Date', which is better than an incorrect date
      }
      return event;
    });
  }
  markAndFilterEstimatedTransportEvents(transportEvents) {
    return transportEvents
      .map((event) => {
        if (event.event.includes('estimated')) {
          event.estimated = true;

          if (event.rawEvents.length === 0) {
            // remove estimated transport events whose raw events have been matched with an actual transport event
            return null;
          }
        }
        return event;
      })
      .compact();
  }

  createUnifiedEvent(unaddedRawEvents) {
    const firstEvent = unaddedRawEvents[0];
    const isEstimated = unaddedRawEvents.every((event) => event.estimated);
    const isMissed = firstEvent.eventType === 'missed';
    const isFloating = firstEvent.eventType === 'floating';
    const eventWithEstimatedTransportEvent = [
      'rail_unloaded',
      'rail_arrived',
      'arrived_at_inland_destination',
    ].includes(firstEvent.event);
    const isMissedBecauseEstimated = isEstimated && isMissed && !eventWithEstimatedTransportEvent;

    return {
      rawEvents: unaddedRawEvents.sort((a, b) => compare(a.createdAt, b.createdAt)),
      isRawEventsHeader: isFloating,
      isMissedCreationEventsHeader: isMissed,
      humanizedEventName: firstEvent.humanizedEventName,
      timestamp: firstEvent.timestamp,
      id: firstEvent.id, // needed for toggle expansion, so it doesn't toggle all placeholder unified events when one is clicked
      estimated: isEstimated,
      isMissedBecauseEstimated,
    };
  }

  orderEventsAndGroupRawEvents(events) {
    const unifiedEvents = [];
    let unaddedRawEvents = [];

    events.forEach((event) => {
      event.eventType = this.determineEventType(event);

      if (unaddedRawEvents.length && unaddedRawEvents[0].eventType !== event.eventType) {
        unifiedEvents.push(this.createUnifiedEvent(unaddedRawEvents));
        unaddedRawEvents = [];
      }

      if (event.isTransportEvent) {
        unifiedEvents.push(event);
      } else {
        unaddedRawEvents.push(event);
      }
    });
    if (unaddedRawEvents.length) {
      unifiedEvents.push(this.createUnifiedEvent(unaddedRawEvents));
    }
    return unifiedEvents;
  }

  determineEventType(event) {
    if (event.isTransportEvent) return 'transport';
    if (event.transportEvent === 'raw') return 'floating';
    return 'missed';
  }
}

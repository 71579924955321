/* eslint-disable ember/no-controller-access-in-routes */

import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { isBlank } from '@ember/utils';
import posthog from 'posthog-js';
import ENV from 'tnt-ui/config/environment';

export default class ApplicationRoute extends Route {
  @service crud;
  @service metrics;
  @service session;
  @service sessionSetup;
  @service updateNotify;
  @service message;
  @service router;
  @service routeLayout;
  @service featureFlags;
  @service posthogFeatures;
  @service activityManager;
  @service localStorage;
  @service broadcast;

  get isNotSignedIn() {
    return isBlank(this.localStorage.getItem('signedInAs'));
  }

  async beforeModel(transition) {
    // When in maintenance page redirect to maintence page unless we are already there
    if (ENV.maintenanceMode == 'true' && transition.to.name != 'maintenance') {
      transition.abort();
      this.router.transitionTo('maintenance');
    }

    await this.session.setup();

    // Do not allow visiting maintenance page when not in maintenance
    if (ENV.maintenanceMode != 'true' && transition.to.name == 'maintenance') {
      transition.abort();
      this.router.transitionTo('dashboard');
    }

    if (this.isNotSignedIn) {
      this.metrics.activateAdapters([
        {
          name: 't49-segment',
          environments: ['staging', 'production'],
          config: {
            key: ENV.APP.SEGMENT_KEY,
          },
        },
      ]);
    }

    if (['staging', 'production', 'demo'].includes(ENV.environment)) {
      posthog.init(ENV.posthogApiKey, {
        api_host: 'https://us.i.posthog.com',
        session_recording: { recordCrossOriginIframes: true },
      });
    }

    this.checkOnUpdate();
    await this.sessionSetup._loadCurrentUser();
    await this.setupSockets();
    await this._trialCheck();
  }

  async _trialCheck() {
    const currentUser = this.session.currentUser;
    const billingAccount = await currentUser?.currentAccount?.billingAccount;

    if (billingAccount && !billingAccount.get('isTrialActive') && !billingAccount.get('isCustomer')) {
      this.router.transitionTo('account-locked');
    }
  }

  @action
  checkOnUpdate() {
    this.updateNotify.on('updated', () => {
      const controller = this.controllerFor('application');
      controller.set('showNewVersionBanner', true);
    });
  }

  @action
  async setupSockets() {
    // this.activityManager.setupSocket();
    this.broadcast.setupSocket();

    if (this.featureFlags.isEnabled('myContainers') || this.posthogFeatures.isPosthogFeatureEnabled('my-containers')) {
      this.broadcast.on('user-cargo.reload', async () => {
        await this.loadUserCargos();
        this.message.info('My Containers list reloaded');
      });
      this.loadUserCargos(); // No need to block main thread
    }
  }

  @action
  loadUserCargos() {
    return this.session.currentUser?.loadUserCargos();
  }

  @action
  setProperty(object, attribute, value) {
    this.crud.setProperty(object, attribute, value);
  }

  @action
  save(model, showSavedMessage = false) {
    return this.crud.save(model, showSavedMessage);
  }

  @action
  routeToSearch(query) {
    this.router.transitionTo('search', { queryParams: { searchVal: query } });
  }

  @action
  requestDemo(buttonSource = 'banner') {
    this.metrics.trackEvent({
      event: 'Request Demo Clicked',
      buttonSource: buttonSource,
    });
    if (window.Intercom) {
      window.Intercom('showNewMessage', 'I would like to request a demo!');
    }
  }

  @action
  error(response) {
    window.console.error(response);
    if (response.errors && response.errors.length > 0) {
      let error = response.errors[0];
      if (error.status === '401') {
        this.router.transitionTo('sign-in');
      } else if (['404', '403'].includes(error.status)) {
        this.router.transitionTo('not-found');
      } else if (['500', '400'].includes(error.status)) {
        this.message.error('Woops! Something went wrong. Please try again.', {
          classPrefix: 'custom-notify',
          closeAfter: 8000,
        });
        //TODO use transition.targetName to transition based on target (e.g. 'quote-requests.request')
      }
    }
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.set('showNewVersionBanner', false);
  }
}

/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { filter, notEmpty } from '@ember/object/computed';
import { isEqual, isPresent } from '@ember/utils';
import { apiAction } from '@mainmatter/ember-api-actions';
import moment from 'moment-timezone';
import { usedAs } from 'tnt-ui/utils/decorators';

@usedAs(['notable'])
export default class ContainerModel extends Model {
  @belongsTo('shipment-v2', { async: false }) shipment;
  @belongsTo('route', { async: false }) route;
  @belongsTo('terminal-v2', { async: false }) podTerminal;
  @belongsTo('terminal-v2', { async: false }) pickupFacility;
  @hasMany('raw-event') rawEvents;
  @hasMany('transport-event') transportEvents;
  @belongsTo('user-container', { async: false }) userCargo;

  @attr('string') number;
  @attr('string', { defaultValue: 'new' }) currentStatus;

  // V2 Attributes
  @attr('string') sealNumber;
  @attr('date') createdAt;
  @attr() refNumbers;
  @attr('string') equipmentType;
  @attr('number') equipmentLength;
  @attr('string') equipmentHeight;
  @attr('number') weightInLbs;
  @attr() feesAtPodTerminal;
  @attr() holdsAtPodTerminal;
  @attr('date') pickupLfd;
  @attr('date') pickupAppointmentAt;
  @attr('string') podFullOutChassisNumber;
  @attr('string') locationAtPodTerminal;
  @attr('boolean') availabilityKnown;
  @attr('boolean') availableForPickup;
  @attr('date') podArrivedAt;
  @attr('date') podDischargedAt;
  @attr('date') finalDestinationFullOutAt;
  @attr('string') finalDestinationTimezone;
  @attr('date') podFullOutAt;
  @attr('string') podTimezone;
  @attr('date') emptyTerminatedAt;
  @attr('string') emptyTerminatedTimezone;
  @attr('date') terminalCheckedAt;
  @attr('date') shipmentLastTrackingRequestAt;
  @attr('date') podLastTrackingRequestAt;
  @attr('array') currentIssues;
  @attr('string') confidenceReason;
  @attr('string') confidenceLevel;

  // Rail Attributes
  @attr('string') podRailCarrierScac;
  @attr('string') indRailCarrierScac;
  @attr('date') podRailLoadedAt;
  @attr('date') podRailDepartedAt;
  @attr('date') indEtaAt;
  @attr('date') indAtaAt;
  @attr('date') indRailUnloadedAt;
  @attr() indFacilityHolds;
  @attr() indFacilityFees;
  @attr('date') indFacilityLfdOn;

  @filter('rawEvents', (e) => !e.estimated) actualContainerEvents;

  @notEmpty('rawEvents') hasActualContainerEvents;

  get cargo() {
    return this.store.peekRecord('cargo', this.id);
  }

  get containerType() {
    return this.equipmentType;
  }

  get containerLength() {
    return this.equipmentLength;
  }

  get containerHeight() {
    return this.equipmentHeight;
  }

  get railCarrierScac() {
    if (isEqual(this.podRailCarrierScac, this.indRailCarrierScac)) {
      return this.podRailCarrierScac;
    } else if (isPresent(this.podRailCarrierScac) && isPresent(this.indRailCarrierScac)) {
      return `${this.podRailCarrierScac} → ${this.indRailCarrierScac}`;
    } else {
      return this.podRailCarrierScac || this.indRailCarrierScac;
    }
  }

  get pickedUpAt() {
    if (this.podFullOutAt) {
      return moment(
        moment.tz(this.podFullOutAt, this.podTimezone || 'Etc/UTC').format('MM-DD-YYYY'),
        'MM-DD-YYYY',
      ).toDate();
    }

    if (this.finalDestinationFullOutAt) {
      return moment(
        moment.tz(this.finalDestinationFullOutAt, this.finalDestinationTimezone || 'Etc/UTC').format('MM-DD-YYYY'),
        'MM-DD-YYYY',
      ).toDate();
    }

    return null;
  }

  get daysSincePickup() {
    let difference = null;
    if (this.pickedUpAt) {
      difference = this.dateDiffInDays(this.pickedUpAt, new Date());
    }
    return difference != null && difference >= 0 ? difference : undefined;
  }

  get indDaysSincePickup() {
    let difference = null;
    if (this.finalDestinationFullOutAt) {
      const finalDestinationFullOutAt = moment(
        moment.tz(this.finalDestinationFullOutAt, this.finalDestinationTimezone || 'Etc/UTC').format('MM-DD-YYYY'),
        'MM-DD-YYYY',
      ).toDate();
      difference = this.dateDiffInDays(finalDestinationFullOutAt, new Date());
    }
    return difference != null && difference >= 0 ? difference : undefined;
  }

  get pickupFacilityIcon() {
    if (this.pickupFacility) {
      if (this.pickupFacility.get('isRail')) {
        return `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="18.0006" rx="4" fill="#FFEDD5"/>
          <path d="M13 4.12506C13.6211 4.12506 14.125 4.62899 14.125 5.25011V7.50022H5.875V5.25011C5.875 4.62899 6.37891 4.12506 7 4.12506H13ZM5.875 11.2504V8.62528H14.125V11.2504C14.125 11.8715 13.6211 12.3755 13 12.3755H7C6.37891 12.3755 5.875 11.8715 5.875 11.2504ZM7 3C5.75781 3 4.75 4.00786 4.75 5.25011V11.2504C4.75 12.2536 5.40625 13.1021 6.31094 13.3927L5.66406 14.0396C5.44375 14.2599 5.44375 14.6162 5.66406 14.8342C5.88437 15.0522 6.24063 15.0545 6.45859 14.8342L7.79453 13.4982H12.2055L13.5414 14.8342C13.7617 15.0545 14.118 15.0545 14.3359 14.8342C14.5539 14.6139 14.5562 14.2576 14.3359 14.0396L13.6891 13.3927C14.5938 13.1021 15.25 12.2536 15.25 11.2504V5.25011C15.25 4.00786 14.2422 3 13 3H7ZM10 11.4379C10.2486 11.4379 10.4871 11.3391 10.6629 11.1633C10.8387 10.9875 10.9375 10.749 10.9375 10.5004C10.9375 10.2517 10.8387 10.0132 10.6629 9.83742C10.4871 9.6616 10.2486 9.56282 10 9.56282C9.75136 9.56282 9.5129 9.6616 9.33709 9.83742C9.16127 10.0132 9.0625 10.2517 9.0625 10.5004C9.0625 10.749 9.16127 10.9875 9.33709 11.1633C9.5129 11.3391 9.75136 11.4379 10 11.4379Z" fill="#EA580C"/>
          </svg>`;
      } else {
        return `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="20" height="18.0006" rx="4" fill="#E5F2FF"/>
          <path d="M10.6667 5.33276C10.6667 5.50958 10.5965 5.67916 10.4714 5.80419C10.3464 5.92922 10.1768 5.99946 10 5.99946C9.82318 5.99946 9.6536 5.92922 9.52856 5.80419C9.40353 5.67916 9.33329 5.50958 9.33329 5.33276C9.33329 5.15594 9.40353 4.98637 9.52856 4.86134C9.6536 4.73631 9.82318 4.66606 10 4.66606C10.1768 4.66606 10.3464 4.73631 10.4714 4.86134C10.5965 4.98637 10.6667 5.15594 10.6667 5.33276ZM10 3.66602C9.07911 3.66602 8.33322 4.41189 8.33322 5.33276C8.33322 6.08072 8.82701 6.71408 9.50622 6.92451C9.50205 6.94951 9.49997 6.97451 9.49997 6.99951V7.66621H8.4999C8.2228 7.66621 7.99987 7.88914 7.99987 8.16624C7.99987 8.44333 8.2228 8.66626 8.4999 8.66626H9.49997V13.3332H8.99993C7.46442 13.3332 6.21433 12.1123 6.1685 10.5872L6.5081 10.8789C6.71853 11.058 7.03314 11.0351 7.21232 10.8247C7.39149 10.6143 7.36858 10.2997 7.15815 10.1205L5.9914 9.12045C5.80389 8.96003 5.52679 8.96003 5.34136 9.12045L4.17462 10.1205C3.96419 10.2997 3.94127 10.6164 4.12045 10.8247C4.29962 11.033 4.61631 11.058 4.82466 10.8789L5.16635 10.5851C5.21219 12.6623 6.9123 14.3332 8.99993 14.3332H10H11.0001C13.0877 14.3332 14.7878 12.6623 14.8337 10.5851L15.1753 10.8789C15.3858 11.058 15.7004 11.0351 15.8796 10.8247C16.0587 10.6143 16.0358 10.2997 15.8254 10.1205L14.6586 9.12045C14.4711 8.96003 14.1961 8.96003 14.0086 9.12045L12.8419 10.1205C12.6314 10.2997 12.6085 10.6164 12.7877 10.8247C12.9669 11.033 13.2835 11.058 13.4919 10.8789L13.8315 10.5872C13.7857 12.1123 12.5356 13.3332 11.0001 13.3332H10.5V8.66626H11.5001C11.7772 8.66626 12.0001 8.44333 12.0001 8.16624C12.0001 7.88914 11.7772 7.66621 11.5001 7.66621H10.5V6.99951C10.5 6.97451 10.498 6.94951 10.4938 6.92451C11.173 6.71408 11.6668 6.08072 11.6668 5.33276C11.6668 4.41189 10.9209 3.66602 10 3.66602Z" fill="#007BFF"/>
          </svg>`;
      }
    } else {
      return `<svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1894_207504)">
        <rect width="20" height="18.0006" rx="4" fill="#E1E3E5"/>
        <path d="M13.0164 8.85654C12.7703 9.43782 12.4211 10.08 12.0203 10.7293C11.3547 11.8122 10.5813 12.8599 10 13.6029C9.41875 12.8575 8.64531 11.8122 7.97969 10.7293C7.57891 10.08 7.22969 9.43782 6.98359 8.85654C6.73047 8.25651 6.625 7.80649 6.625 7.51585C6.625 5.65247 8.13672 4.14068 10 4.14068C11.8633 4.14068 13.375 5.65247 13.375 7.51585C13.375 7.80649 13.2695 8.25651 13.0164 8.85654ZM10.5555 14.7162C11.7578 13.2114 14.5 9.56439 14.5 7.51585C14.5 5.03135 12.4844 3.01562 10 3.01562C7.51562 3.01562 5.5 5.03135 5.5 7.51585C5.5 9.56439 8.24219 13.2114 9.44453 14.7162C9.73281 15.0748 10.2672 15.0748 10.5555 14.7162Z" fill="#6C757D"/>
        </g>
        <defs>
        <clipPath id="clip0_1894_207504">
        <rect width="20" height="18.0006" fill="white"/>
        </clipPath>
        </defs>
        </svg>`;
    }
  }

  get pickupFacilityName() {
    let name = '';

    if (this.pickupFacility) {
      let facilityName = this.pickupFacility.get('nickname');

      if (facilityName) {
        name += facilityName;
      }

      const facilityFirmsCode = this.pickupFacility.get('firmsCode');

      if (facilityFirmsCode) {
        name += ` (${facilityFirmsCode})`;
      }
    }

    return name.trim();
  }

  get pickupFacilityTimeZone() {
    return this.finalDestinationTimezone || this.podTimezone;
  }

  get indAtaAtTimezone() {
    if (this.indAtaAt) {
      return moment(
        moment.tz(this.indAtaAt, this.finalDestinationTimezone || 'Etc/UTC').format('MM-DD-YYYY'),
        'MM-DD-YYYY',
      ).toDate();
    } else {
      return undefined;
    }
  }

  get indEtaAtTimezone() {
    if (this.indEtaAt) {
      return moment(
        moment.tz(this.indEtaAt, this.finalDestinationTimezone || 'Etc/UTC').format('MM-DD-YYYY'),
        'MM-DD-YYYY',
      ).toDate();
    } else {
      return undefined;
    }
  }

  dateDiffInDays(a, b) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  async fetchRawEvents() {
    return await apiAction(this, {
      method: 'GET',
      path: 'raw_events',
    });
  }

  async loadRawEvents() {
    const data = await this.fetchRawEvents();

    this.store.push(data);

    return this.rawEvents;
  }
}

/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

export default class CargoEventHistoryModal extends Component {
  get previousEventsArray() {
    const previousEvents = [];
    let currentEvent = this.args.event;
    while (currentEvent.previousVersion) {
      currentEvent = currentEvent.previousVersion;
      previousEvents.push(currentEvent);
    }
    return previousEvents;
  }
}

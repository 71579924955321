import Service, { service } from '@ember/service';
import { action } from '@ember/object';
//@ts-ignore
import type Owner from '@ember/owner';
import { camelize } from '@ember/string';
import { tracked } from '@glimmer/tracking';
import ENV from 'tnt-ui/config/environment';
import type SessionService from 'tnt-ui/services/session';

type FeatureFlag = string;

export default class FeatureFlagsService extends Service {
  @service declare session: SessionService;

  @tracked flags = new Set();

  availableFlags = Object.keys(ENV.featureFlags);

  get enabledFlags() {
    return Array.from(this.flags) as FeatureFlag[];
  }

  constructor(owner: Owner) {
    super(owner);

    this.setup();
  }

  @action
  toggleFlags(enabledFlags: FeatureFlag[]) {
    for (const flag of this.availableFlags) {
      if (enabledFlags.includes(flag)) {
        this.enable(flag);
      } else {
        this.disable(flag);
      }
    }

    const { flags } = this;
    this.flags = flags;

    this.saveEnabledFlagsToLocalStorage(this.enabledFlags);
  }

  disable = (ff: FeatureFlag) => ff && this.flags.delete(ff);
  enable = (ff: FeatureFlag) => ff && this.flags.add(ff);

  isDisabled = (ff: FeatureFlag) => !this.isEnabled(ff);
  isEnabled = (ff: FeatureFlag) => {
    const flagName = camelize(ff);
    return this.flags.has(flagName) || !!this.session.currentUser?.currentAccount?.isFlagEnabled?.(flagName);
  };

  /* Enables feature flags specified via query params.
   * For example, to enable the `foo`, and `bar` feature flags:
   *
   * http://localhost:4200/containers?enabledFeatures=foo,bar
   */
  enableFeaturesViaQueryParams() {
    const params = new URLSearchParams(window.location.search);
    const enabledFeatures = (params.get('enabledFeatures') ?? '').split(',');

    // We're leaving this console.log here for debugging purposes in production
    console.log('Query params enabledFeatures', enabledFeatures);

    for (const feature of enabledFeatures) {
      this.enable(feature);
    }
  }

  setup() {
    this.flags.clear();
    this.setupFeatures(ENV.featureFlags);
    this.enableFeaturesViaQueryParams();
    this.enableFeaturesViaLocalStorage();
  }

  setupFeatures(featureFlags = {}) {
    for (const [featureFlag, isEnabled] of Object.entries(featureFlags)) {
      if (isEnabled) {
        this.enable(featureFlag);
      } else {
        this.disable(featureFlag);
      }
    }
  }

  saveEnabledFlagsToLocalStorage(enabledFlags: FeatureFlag[]) {
    if (enabledFlags.length === 0) {
      window.localStorage.removeItem('featureFlags');
    } else {
      window.localStorage.setItem('featureFlags', JSON.stringify(enabledFlags));
    }
  }

  enableFeaturesViaLocalStorage() {
    const enabledFlags = JSON.parse(window.localStorage.getItem('featureFlags') ?? '[]');

    enabledFlags && this.toggleFlags(enabledFlags);
  }
}

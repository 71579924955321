/* import __COLOCATED_TEMPLATE__ from './edit-trial.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, get, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';

export default class ModalsEditTrial extends Component {
  @service modals;
  @service router;
  @service notify;

  @tracked selectedStartDate;
  @tracked selectedEndDate;
  @tracked reason;

  get account() {
    return this.args.content.account;
  }

  get flagName() {
    return this.args.content.flagName;
  }

  unknownProperty(key) {
    return this.args[key] || this.args?.content[key];
  }

  @action
  onDidInsert() {
    const startDate = get(this.account, `flagDetails.${this.flagName}.startDate`);
    const endDate = get(this.account, `flagDetails.${this.flagName}.endDate`);
    const reason = get(this.account, `flagDetails.${this.flagName}.reason`);

    this.selectedStartDate = startDate ? moment(startDate).startOf('day').toDate() : null;
    this.selectedEndDate = endDate ? moment(endDate).endOf('day').toDate() : null;
    this.reason = reason ?? '';
  }

  @action
  onSelectStartDate({ dateInstance }) {
    const startDate = moment(dateInstance).startOf('day');
    const endDate = this.selectedEndDate ? moment(this.selectedEndDate).endOf('day') : undefined;

    this.selectedStartDate = startDate.toDate();
    if (endDate && startDate.isAfter(endDate)) {
      this.onClearEndDate();
    }
  }

  @action
  onClearStartDate() {
    this.selectedStartDate = null;
    this.onClearEndDate();
  }

  @action
  onClearEndDate() {
    this.selectedEndDate = null;
  }

  @action
  onSelectEndDate({ dateInstance }) {
    this.selectedEndDate = moment(dateInstance).endOf('day').toDate();
  }

  @action
  onConfirm() {
    const startDate = this.selectedStartDate
      ? moment(this.selectedStartDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS')
      : null;
    const endDate = this.selectedEndDate
      ? moment(this.selectedEndDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS')
      : null;

    set(this.account, `flagDetails.${this.flagName}`, {
      startDate,
      endDate,
      reason: this.reason,
    });
    this.closeModal();
  }

  @action
  closeModal() {
    this.modals.close('edit-trial');
  }
}

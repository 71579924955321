import fetch from 'fetch';
import V2ApiAdapter from './v2-api';

export default class ShipmentV2Adapter extends V2ApiAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    return super.buildURLV2(modelName, id, snapshot, requestType, query);
  }

  urlForFindRecord(id, modelName, snapshot) {
    if (snapshot.adapterOptions?.useAnonymousEndpoint) {
      const { shareId } = snapshot.adapterOptions;
      return `${this.urlPrefix()}/shipments/${id}/share/${shareId}`;
    }
    return this.buildURL(modelName, id, snapshot);
  }

  findRecord(store, type, id, snapshot) {
    let url = this.urlForFindRecord(id, type.modelName, snapshot);

    if (snapshot.include) {
      url += `?include=${snapshot.include}`;
    }
    if (snapshot.adapterOptions?.include_terminal_events) {
      url += (url.includes('?') ? '&' : '?') + 'include_terminal_events=true';
    }

    return this.ajax(url, 'GET');
  }

  async stopTracking(shipment) {
    const response = await fetch(`${this.buildURL('shipment', shipment.id)}/stop_tracking`, {
      method: 'PATCH',
      headers: {
        ...this.headers,
      },
    });

    const data = await response.json();

    if (this.isSuccess(response.status)) {
      this.store.pushPayload('shipment', data);
    } else {
      throw data.errors[0]?.detail;
    }
  }

  async resumeTracking(shipment) {
    const response = await fetch(`${this.buildURL('shipment', shipment.id)}/resume_tracking`, {
      method: 'PATCH',
      headers: {
        ...this.headers,
      },
    });

    const data = await response.json();

    if (this.isSuccess(response.status)) {
      this.store.pushPayload('shipment', data);
    } else {
      throw data.errors[0]?.detail;
    }
  }
}
